<template>
  <div v-if="generalDashboard">
    <b-row>
      <b-col
        v-for="punctuation in punctuations"
        :key="punctuation.title"
        class="mb-6"
        cols-xl="5"
        cols-lg="5"
        cols-md="3"
        cols-sm="2"
      >
        <punctuation
          :icon="punctuation.icon"
          :title="punctuation.title"
          :punctuation="punctuation.punctuation"
          :percentage="punctuation.percentage"
          :iconClass="punctuation.iconClass"
          :visibility="punctuation.visibility"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="9" md="8" sm="12">
        <avg-max-risk
          :series="series"
          :discrete="discrete"
          :startDate="startDate"
          :base="10"
          :title="$t('dashboard.status.score')"
          :subtitle="$t('dashboard.risk.subtitle')"
        />
      </b-col>
      <b-col lg="3" md="4" sm="12">
        <KymatioScore
          :title="$t('dashboard.status.score')"
          :score="
            generalDashboard.score?.series[
              generalDashboard.score?.series.length - 1
            ]
              ? generalDashboard.score?.series[
                  generalDashboard.score?.series.length - 1
                ].value
              : 0
          "
          :base="10"
        ></KymatioScore>
        <punctuation
          :icon="generalPunctuation.icon"
          :title="generalPunctuation.title"
          :punctuation="generalPunctuation.punctuation"
          :evolution="generalPunctuation.evolution"
          :percentage="generalPunctuation.percentage"
          :iconClass="generalPunctuation.iconClass"
          :visibility="generalPunctuation.visibility"
          :half="true"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import moment from "moment-timezone";
import AvgMaxRisk from "@/components/dashboard/AvgMaxRisk.component";
import Punctuation from "@/components/dashboard/Punctuation.component";
import KymatioScore from "@/views/controller/dashboard/KymatioScore";

export default {
  name: "GeneralDashboard",
  components: {
    AvgMaxRisk,
    Punctuation,
    KymatioScore
  },
  computed: {
    ...mapGetters("Company", ["generalDashboard"]),
    ...mapGetters("Auth", ["hasModule"]),
    ...mapGetters("Constants", ["isConstantsActive"]),
    ...mapGetters("Controller", ["companySelected", "isCompanySelectedLoaded"]),
    punctuations() {
      return [
        {
          module: "awareness",
          icon: "shield-protected.svg",
          title: "cybersecurity",
          punctuation: this.hasModule(["awareness"])
            ? this.generalDashboard.cybersecurity.value
            : "-",
          percentage: false,
          iconClass: "tc3",
          visibility: this.hasModule(["awareness"])
        },
        {
          module: "phishing",
          icon: "fish.svg",
          title: "phishing",
          punctuation: this.hasModule(["phishing"])
            ? this.generalDashboard.phishing?.value
            : "-",
          percentage: false,
          iconClass: "tc5",
          visibility: this.hasModule(["phishing"])
        },
        {
          module: "abs",
          icon: "key.svg",
          title: "abs",
          punctuation: this.hasModule(["abs"])
            ? this.generalDashboard.abs.value
            : "-",
          percentage: false,
          iconClass: "tc4",
          visibility: this.hasModule(["abs"])
        },
        {
          module: "wellbeing",
          icon: "home-heart.svg",
          title: "wellbeing",
          punctuation: this.hasModule(["wellbeing"])
            ? this.generalDashboard.wellbeing.value
            : "-",
          percentage: false,
          iconClass: "tc2",
          visibility: this.hasModule(["wellbeing"])
        },
        {
          module: "burnout",
          icon: "fire.svg",
          title: "burnout",
          punctuation: this.hasModule(["burnout"])
            ? this.generalDashboard.burnout.value
            : "-",
          percentage: false,
          iconClass: "tc7",
          visibility: this.hasModule(["burnout"])
        }
      ];
    },
    generalPunctuation() {
      return {
        icon: "chain.svg",
        title: "progress",
        punctuation: this.generalDashboard.participation.value,
        percentage: false,
        iconClass: "tc6",
        visibility: true
      };
    },
    discrete() {
      return this.generalDashboard.score.discrete;
    },
    startDate() {
      let date = moment();
      _.each(this.series, element => {
        let first = _.first(_.orderBy(element.data, el => el[0], ["asc"]));
        first = first ? first[0] : false;

        if (first && moment(first).isBefore(moment(date))) {
          date = first;
        }
      });

      return date;
    },
    series() {
      let series = [
        {
          name: this.$t("dashboard.risk.average"),
          type: "area",
          data: _.map(this.generalDashboard.score.series, element => [
            moment(element.date),
            element.value
          ]),
          color: "#0bb783"
        },
        {
          name: this.$t("dashboard.status.progress"),
          type: "line",
          data: _.map(
            this.generalDashboard.participation.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#00CDFF"
        },
        {
          name: this.$t("dashboard.status.cybersecurity"),
          type: "line",
          service: "awareness",
          data: _.map(
            this.generalDashboard.cybersecurity.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#d23333"
        },
        {
          name: this.$t("dashboard.status.phishing"),
          type: "line",
          service: "phishing",
          data: _.map(
            this.generalDashboard.phishing.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#ffeb3b"
        },
        {
          name: this.$t("dashboard.status.abs"),
          type: "line",
          service: "abs",
          data: _.map(this.generalDashboard.abs.evolution.series, element => [
            moment(element.date),
            element.value
          ]),
          color: "#ea48e6"
        },
        {
          name: this.$t("dashboard.status.wellbeing"),
          type: "line",
          service: "wellbeing",
          data: _.map(
            this.generalDashboard.wellbeing.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#3f51b5"
        },
        {
          name: this.$t("dashboard.status.burnout"),
          type: "line",
          service: "burnout",
          data: _.map(
            this.generalDashboard.burnout.evolution.series,
            element => [moment(element.date), element.value]
          ),
          color: "#ff9d00"
        }
      ];
      return series;
    }
  },
  methods: {
    ...mapActions("Company", ["loadGeneralDashboard"])
  },
  watch: {
    isConstantsActive(value) {
      if (localStorage.getItem("managedCompanyId") && value === true) {
        this.loadGeneralDashboard();
      }
    },
    isCompanySelectedLoaded(value) {
      if (localStorage.getItem("managedCompanyId") && value === true) {
        this.loadGeneralDashboard();
      }
    }
  },
  mounted() {
    if (
      localStorage.getItem("managedCompanyId") &&
      !this.companySelected &&
      this.isConstantsActive
    ) {
      this.loadGeneralDashboard();
    }
  }
};
</script>
