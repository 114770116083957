var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.generalDashboard)?_c('div',[_c('b-row',_vm._l((_vm.punctuations),function(punctuation){return _c('b-col',{key:punctuation.title,staticClass:"mb-6",attrs:{"cols-xl":"5","cols-lg":"5","cols-md":"3","cols-sm":"2"}},[_c('punctuation',{attrs:{"icon":punctuation.icon,"title":punctuation.title,"punctuation":punctuation.punctuation,"percentage":punctuation.percentage,"iconClass":punctuation.iconClass,"visibility":punctuation.visibility}})],1)}),1),_c('b-row',[_c('b-col',{attrs:{"lg":"9","md":"8","sm":"12"}},[_c('avg-max-risk',{attrs:{"series":_vm.series,"discrete":_vm.discrete,"startDate":_vm.startDate,"base":10,"title":_vm.$t('dashboard.status.score'),"subtitle":_vm.$t('dashboard.risk.subtitle')}})],1),_c('b-col',{attrs:{"lg":"3","md":"4","sm":"12"}},[_c('KymatioScore',{attrs:{"title":_vm.$t('dashboard.status.score'),"score":_vm.generalDashboard.score?.series[
            _vm.generalDashboard.score?.series.length - 1
          ]
            ? _vm.generalDashboard.score?.series[
                _vm.generalDashboard.score?.series.length - 1
              ].value
            : 0,"base":10}}),_c('punctuation',{attrs:{"icon":_vm.generalPunctuation.icon,"title":_vm.generalPunctuation.title,"punctuation":_vm.generalPunctuation.punctuation,"evolution":_vm.generalPunctuation.evolution,"percentage":_vm.generalPunctuation.percentage,"iconClass":_vm.generalPunctuation.iconClass,"visibility":_vm.generalPunctuation.visibility,"half":true}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }